import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import { useEffect } from 'react';
import './custom.css'
import Privacy from './components/Privacy';
import Terms from './components/Terms';
//import GetPacks from './components/GetPacks';
import ScrollToTop from './components/ScrollToTop';
//import CandyMachine from './components/CandyMachine/App';
//import TravelerPacksButton from './components/TravelerPacksButton/App';
//import './bootstrap/css/bootstrap.css';
//import * as Bootstrap from './bootstrap/js/bootstrap';
import Art from './components/Art';

export default function () {
    useEffect(() => {
        document.title = 'Diamond Hand: Legends';
    }, []);

    return (
        <Layout>
            <ScrollToTop />
            <Route exact path='/' component={Home} />
            {/*<Route exact path='/get-packs' component={GetPacks} />*/}
            <Route exact path='/privacy-policy' component={Privacy} />
            <Route exact path='/terms-and-conditions' component={Terms} />
            {/*<Route exact path='/candy-machine' component={CandyMachine} />*/}
            {/*<Route exact path='/buy-packs-button' component={TravelerPacksButton} />*/}
            <Route exact path='/art' component={Art} />
        </Layout>
    );
}