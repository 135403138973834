import React, { Suspense } from 'react';
//import { useEffect } from 'react';
//import { connect } from 'react-redux';
//import Features from './home/Features';
//import Lore from './home/Lore';
//import MainSlider from './home/MainSlider';
//import Partners from './home/Partners';
//import PresaleStatus from './home/PresaleStatus';
//import PackDescriptions from './home/PackDescriptions';
//import Roadmap from './home/Roadmap';
//import PageFooter from './PageFooter';
import { Spinner } from 'react-bootstrap';
const MainSlider = React.lazy(() => import('./home/MainSlider'));
const Lore = React.lazy(() => import('./home/Lore'));
const Features = React.lazy(() => import('./home/Features'));
const PackDescriptions = React.lazy(() => import('./home/PackDescriptions'));
const Roadmap = React.lazy(() => import('./home/Roadmap'));
//const Partners = React.lazy(() => import('./home/Partners'));
const Team = React.lazy(() => import('./home/Team'));
const PageFooter = React.lazy(() => import('./PageFooter'));

export default function Home() {
    return (
        <div>
            <Suspense fallback={<div className="homeSection diamondBezelSection" style={{ marginTop: "75px"}}><Spinner animation="border" variant="info" /></div>}>
                <MainSlider />
            </Suspense>
            <Suspense fallback={<div className="homeSection diamondBezelSection"><Spinner animation="border" variant="info" /></div>}>
                <Lore />
            </Suspense>
            <Suspense fallback={<div className="homeSection diamondBezelSection"><Spinner animation="border" variant="info" /></div>}>
                <Features />
            </Suspense>
            <Suspense fallback={<div className="homeSection diamondBezelSection"><Spinner animation="border" variant="info" /></div>}>
                <PackDescriptions />
            </Suspense>
            <Suspense fallback={<div className="homeSection diamondBezelSection"><Spinner animation="border" variant="info" /></div>}>
                <Roadmap />
            </Suspense>
            {/*<Suspense fallback={<div className="homeSection diamondBezelSection"><Spinner animation="border" variant="info" /></div>}>*/}
            {/*    <Partners />*/}
            {/*</Suspense>*/}
            {/*<Suspense fallback={<div className="homeSection diamondBezelSection"><Spinner animation="border" variant="info" /></div>}>*/}
            {/*    <Team />*/}
            {/*</Suspense>*/}
            <Suspense fallback={<div className="homeSection diamondBezelSection"><Spinner animation="border" variant="info" /></div>}>
                <PageFooter />
            </Suspense>
        </div>
    );
}