import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTwitter, faInstagram, faMediumM } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import './NavFooter.css';

export default function NavFooter() {
    return (
        <footer id="navFooter">
            <div id="navFooterTextContainer">
                <div id="navFooterText">
                    <div id="navFooterCopyright">
                        <div>&copy; 2023 Diamond Hand: Legends. All rights reserved.</div>
                    </div>
                    <div id="navFooterLinks">
                        <div id="navFooterIconsContainer">
                            <div id="navFooterIcons">
                                {/*<a href="https://discord.gg/EMsbGW6a6r" target="_blank">*/}
                                {/*    <FontAwesomeIcon icon={faDiscord} className="navFooterIcon" />*/}
                                {/*</a>*/}
                                <a href="https://twitter.com/DHLegends" target="_blank">
                                    <FontAwesomeIcon icon={faTwitter} className="navFooterIcon" />
                                </a>
                                <a href="https://www.instagram.com/diamondhandlegends/" target="_blank">
                                    <FontAwesomeIcon icon={faInstagram} className="navFooterIcon" />
                                </a>
                                {/*<a href="https://medium.com/@DiamondHandLegends" target="_blank">*/}
                                {/*    <FontAwesomeIcon icon={faMediumM} className="navFooterIcon" />*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div id="navFooterLinksDiv">
                            {/*<a href="mailto:info@bluehorizongamestudios.com" target="_blank">Contact Us</a>*/}
                            <Link to="/privacy-policy">Privacy Policy</Link>
                            <Link to="/terms-and-conditions">Terms and Conditions</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}