import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
//import { photos } from "./photos";
import './Art.css';

//const breakpointColumnsObj = {
//    default: 3,
//    1200: 2,
//    700: 1
//};

const photos = [
    {
        src: "./images/gallery/Trailer1_HeroesFightScene_1920x1081-min.jpg",
        width: 16,
        height: 9,
        title: 'Trailer - Heroes Fight Scene'
    },
    {
        src: "./images/gallery/Heroes_Henna_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Heroes - Henna'
    },
    {
        src: "./images/gallery/Trailer1_FloatingIslandFlamehounds_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Trailer - Floating Island Flamehounds'
    },
    {
        src: "./images/gallery/Cards_BlackMagicLove_1920x1440-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Black Magic Love'
    },
    {
        src: "./images/gallery/Trailer1_TowerFightScene_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Trailer - Tower Fight Scene'
    },
    {
        src: "./images/gallery/Cards_WretchedShambler_1920x1483-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Wretched Shambler'
    },
    {
        src: "./images/gallery/Heroes_DeeDee_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Heroes - Dee Dee'
    },
    {
        src: "./images/gallery/Heroes_Yara_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Heroes - Yara'
    },
    {
        src: "./images/gallery/Trailer1_FloatingIsland_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Trailer - Floating Island'
    },
    {
        src: "./images/gallery/Heroes_Gillian_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Heroes - Gillian'
    },
    {
        src: "./images/gallery/Trailer1_SchoolOnCapitalIsle_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Trailer - School on Capital Isle'
    },
    {
        src: "./images/gallery/Cards_DarkPaladin_1920x1440-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Dark Paladin'
    },
    {
        src: "./images/gallery/Cards_DruidJon_1920x1440-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Druid Jon'
    },
    {
        src: "./images/gallery/Heroes_Yhalam_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Heroes - Yhalam'
    },
    {
        src: "./images/gallery/Cards_DruidSarah_1920x1440-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Druid Sarah'
    },
    {
        src: "./images/gallery/Trailer1_TowerConstrunction_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Trailer - Tower Construction'
    },
    {
        src: "./images/gallery/Cards_FuriousFlamehound_1920x1484-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Furious Flamehound'
    },
    {
        src: "./images/gallery/Cards_ArcherDrew_1920x1440-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Archer Drew'
    },
    {
        src: "./images/gallery/Cards_BlackMagic_1920x1440-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Black Magic'
    },
    {
        src: "./images/gallery/Cards_GeodeSkull_1920x1440-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Geode Skull'
    },
    {
        src: "./images/gallery/Cards_Lisa_1920x1080-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Druid Lisa'
    },
    {
        src: "./images/gallery/Cards_Monk_1920x1440-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Monk'
    },
    {
        src: "./images/gallery/Heroes_Lokli_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Heroes - Lokli'
    },
    {
        src: "./images/gallery/Cards_NoOrdinaryRabbit_1438x1078-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - No Ordinary Rabbit'
    },
    {
        src: "./images/gallery/Cards_Stablehand_1920x1440-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Stablehand'
    },
    {
        src: "./images/gallery/Trailer1_PalzoLab_1920x1080-min.jpg",
        width: 16,
        height: 9,
        title: 'Trailer - Palzo Lab'
    },
    {
        src: "./images/gallery/Cards_WizardJohn_1920x1440-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Wizard John'
    },
    {
        src: "./images/gallery/Cards_WizardPatrick_1920x1080-min.jpg",
        width: 4,
        height: 3,
        title: 'Cards - Wizard Patrick'
    },
];

export default function Art() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div id="art">
            <div id="artContainer">
                <div id="artTitle">
                    <div>
                        <h2>Art Gallery</h2>
                        {/*<p id="artTitleDescription"><b>Stay ahead of the herd</b> with the Diamond Hand: Legends presale card packs. Each pack contains 5 cards with 2 layers of rarity. Minimum gauranteed rarities are granted depending on the tier. Presale packs are only available for a limited time.</p>*/}
                    </div>
                </div>
                <Gallery photos={photos} onClick={openLightbox} />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                    ...x,
                                    srcset: x.src,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        </div>
    );
}
