import * as React from 'react';
import { Collapse, Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledCollapse, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faWallet } from '@fortawesome/free-solid-svg-icons';
import './NavMenu.css';
import './Signup.css';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, isModalOpen: boolean, isAboutOpen: boolean, isBlogOpen: boolean }> {
    public state = {
        isOpen: false,
        isModalOpen: false,
        isAboutOpen: false,
        isBlogOpen: false,
    };

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    private toggleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    };

    private closeMenus = () => {
        if (document.getElementById('aboutCollapse').classList.contains('show')) {
            document.getElementById('aboutButtonMobile').click();
        }

        this.setState({
            isOpen: false
        });
    }

    private SignupModal = () => (
        <Modal isOpen={this.state.isModalOpen} id="signupModal">
            <ModalHeader>
                Subscribe to Diamond Hand: Legends
            </ModalHeader>
            <ModalBody>
                <p>Stay ahead of the herd. Get our latest launch updates sent straight to your inbox.</p>
                {/* MailChimp Embedded SignupForm */}
                <div id="mc_embed_signup">
                    <form action="https://gmail.us20.list-manage.com/subscribe/post?u=4db03c48a794e90bf25f2f5f7&amp;id=1a2dbd9244" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                        <div id="mc_embed_signup_scroll">
                            <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
                            <input type="checkbox" value="1" name="group[13237][1]" id="group_1" style={{ display: 'none' }} checked />
                            <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                                <input type="text" name="b_4db03c48a794e90bf25f2f5f7_1a2dbd9244" tabIndex={-1} style={{ display: "none" }} />
                            </div>
                            <div className="clear">
                                <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button buttonPrimary" />
                            </div>
                        </div>
                    </form>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="btn-secondary btn-sm" onClick={this.toggleModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );

    public render() {
        return (
            <div>
                <header id="navHeader">
                    <Navbar id="navNavbar" className="navbar-dark" expand="lg" fixed="top">
                        <div id="mobileNowrap">
                            <NavbarBrand tag={Link} to="/"><img src={'/images/DHLLogoNew_112x64-min.png'} onClick={this.closeMenus} /></NavbarBrand>
                            <NavbarToggler onClick={this.toggle} className="ml-2 mr-2" />
                        </div>
                        <div id="navbarCollapseContainer">
                            <Collapse className="d-lg-inline-flex flex-lg-row" isOpen={this.state.isOpen} navbar>
                                <ul className="navbar-nav flex-grow navMain">
                                    {/*<NavItem id="navGetPacks" onClick={this.closeMenus}>*/}
                                    {/*    <NavLink tag={Link} to="/get-packs" className="buttonPrimary">GET PACKS</NavLink>*/}
                                    {/*</NavItem>*/}

                                    <Tooltip title="Coming Soon!" position="bottom" arrow={true}>
                                        <NavItem id="navGetPacks" onClick={this.closeMenus}>
                                            <NavLink tag={Link} to="#" className="buttonDisabled">BUY DIAMONDS</NavLink>
                                        </NavItem>
                                    </Tooltip>

                                    {/* Blog desktop menu */}
                                    <UncontrolledDropdown className="navDropdown">
                                        <DropdownToggle caret>
                                            Blog
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem><a className="nav-link" href="https://blog.diamondhandlegends.com/news">News</a></DropdownItem>
                                            <DropdownItem><a className="nav-link" href="https://blog.diamondhandlegends.com/lore">Lore</a></DropdownItem>
                                            <DropdownItem><a className="nav-link" href="https://blog.diamondhandlegends.com/">All</a></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {/* Blog mobile menu */}
                                    <div className="navDropdownMobile">
                                        <Button id="blogButtonMobile" className="nav-link">Blog</Button>
                                        <UncontrolledCollapse toggler="#blogButtonMobile" id="blogCollapse">
                                            <div id="blogContent">
                                                <a onClick={this.closeMenus} className="nav-link" href="https://blog.diamondhandlegends.com/news">News</a>
                                                <a onClick={this.closeMenus} className="nav-link" href="https://blog.diamondhandlegends.com/lore">Lore</a>
                                                <a onClick={this.closeMenus} className="nav-link" href="https://blog.diamondhandlegends.com/news">All</a>
                                            </div>
                                        </UncontrolledCollapse>
                                    </div>

                                    {/* About desktop menu */}
                                    <UncontrolledDropdown className="navDropdown">
                                        <DropdownToggle caret>
                                            Whitepaper
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem><a className="nav-link" href="https://diamondhandlegends.gitbook.io/about/" target="_blank">Intro</a></DropdownItem>
                                            <DropdownItem><a className="nav-link" href="https://diamondhandlegends.gitbook.io/about/faq" target="_blank">FAQ</a></DropdownItem>
                                            <DropdownItem><a className="nav-link" href="https://diamondhandlegends.gitbook.io/about/the-game" target="_blank">The Game</a></DropdownItem>
                                            <DropdownItem><a className="nav-link" href="https://diamondhandlegends.gitbook.io/about/what-is-the-in-game-currency" target="_blank">Tokens</a></DropdownItem>
                                            <DropdownItem><a className="nav-link" href="https://diamondhandlegends.gitbook.io/about/the-marketplace" target="_blank">The Marketplace</a></DropdownItem>
                                            <DropdownItem><a className="nav-link" href="https://diamondhandlegends.gitbook.io/about/lore" target="_blank">Lore</a></DropdownItem>
                                            <DropdownItem><a className="nav-link" href="https://diamondhandlegends.gitbook.io/about/roadmap" target="_blank">Roadmap</a></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {/* About mobile menu */}
                                    <div className="navDropdownMobile">
                                        <Button id="aboutButtonMobile" className="nav-link">Whitepaper</Button>
                                        <UncontrolledCollapse toggler="#aboutButtonMobile" id="aboutCollapse">
                                            <div id="aboutContent">
                                                <a onClick={this.closeMenus} className="nav-link" href="https://diamondhandlegends.gitbook.io/about/" target="_blank">Intro</a>
                                                <a onClick={this.closeMenus} className="nav-link" href="https://diamondhandlegends.gitbook.io/about/faq" target="_blank">FAQ</a>
                                                <a onClick={this.closeMenus} className="nav-link" href="https://diamondhandlegends.gitbook.io/about/the-game" target="_blank">The Game</a>
                                                <a onClick={this.closeMenus} className="nav-link" href="https://diamondhandlegends.gitbook.io/about/what-is-the-in-game-currency" target="_blank">Tokens</a>
                                                <a onClick={this.closeMenus} className="nav-link" href="https://diamondhandlegends.gitbook.io/about/the-marketplace" target="_blank">The Marketplace</a>
                                                <a onClick={this.closeMenus} className="nav-link" href="https://diamondhandlegends.gitbook.io/about/lore" target="_blank">Lore</a>
                                                <a onClick={this.closeMenus} className="nav-link" href="https://diamondhandlegends.gitbook.io/about/roadmap" target="_blank">Roadmap</a>
                                            </div>
                                        </UncontrolledCollapse>
                                    </div>
                                    <NavItem>
                                        <NavLink tag={Link} to="/art">Art</NavLink>
                                    </NavItem>
                                    <Tooltip title="Coming Soon!" position="bottom" arrow={true}>
                                        <NavItem>
                                            <div className="nav-link navItemDisabled">Marketplace</div>
                                        </NavItem>
                                    </Tooltip>
                                    <NavItem onClick={this.closeMenus}>
                                        <div className="nav-link" id="signupModalLink" onClick={this.toggleModal}>Signup</div>
                                    </NavItem>
                                </ul>
                            </Collapse>
                            {/*<Collapse className="d-lg-inline-flex flex-lg-row-reverse" id="navbarRightCollapse" isOpen={this.state.isOpen} navbar>*/}
                            {/*    <ul className="navbar-nav flex-grow navMain" id="navbarRight">*/}
                            {/*        <Tooltip title="Connect Wallet" position="bottom" arrow={true}>*/}
                            {/*            <NavItem>*/}
                            {/*                <NavLink tag={Link} to="/#" className="expandingIcon" style={{ marginTop: "-10px" }}><FontAwesomeIcon icon={faWallet} className="navMenuSocialIcon" /></NavLink>*/}
                            {/*            </NavItem>*/}
                            {/*        </Tooltip>*/}
                            {/*    </ul>*/}
                            {/*</Collapse>*/}
                        </div>
                    </Navbar>
                    <this.SignupModal />
                </header>
            </div>
        );
    }
}
