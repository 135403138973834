import { Action, Reducer } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface TravelerPackState {
    quantity: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface IncrementQuantityAction { type: 'INCREMENT_QUANTITY', payload: number }
export interface DecrementQuantityAction { type: 'DECREMENT_QUANTITY', payload: number }
export interface SetQuantityAction { type: 'SET_QUANTITY', payload: number }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = IncrementQuantityAction | DecrementQuantityAction | SetQuantityAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    increment: (quantity: number) => ({ type: 'INCREMENT_QUANTITY', payload: quantity } as IncrementQuantityAction),
    decrement: (quantity: number) => ({ type: 'DECREMENT_QUANTITY', payload: quantity } as DecrementQuantityAction),
    set: (quantity:number) => ({ type: 'SET_QUANTITY', payload: quantity } as SetQuantityAction)
    //set: () => ({ type: 'SET_QUANTITY' })
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const travelerPackReducer: Reducer<TravelerPackState> = (state: TravelerPackState | undefined, incomingAction: Action): TravelerPackState => {
    if (state === undefined) {
        return { quantity: 0 };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'INCREMENT_QUANTITY':
            return { quantity: state.quantity + action.payload };
        case 'DECREMENT_QUANTITY':
            return { quantity: state.quantity - action.payload };
        case 'SET_QUANTITY':
            return { quantity: action.payload };
        default:
            return state;
    }
};

//export default travelerPackReducer;